@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.sample-slider .swiper-wrapper {
  transition-timing-function: linear;
}

@keyframes scroll {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.scrolling-text {
  animation: scroll 10s linear infinite;
}
